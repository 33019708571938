body {
  margin: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

.button {
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
